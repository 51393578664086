import UIkit from 'uikit'

export default {
  init() {
    console.log('lessons!!')
    // Switcher
    const switcher = document.getElementById('lessons-switcher')
    const switcherItems = switcher && switcher.querySelectorAll('li a')

    // get anchor from url
    const anchor = window.location.hash
    console.log(anchor)

    if (anchor) {
      // Get index of list by anchor
      const index = Array.prototype.indexOf.call(
        switcherItems,
        switcher.querySelector(`a[href="${anchor}"]`)
      )
      // Open tab based on index
      UIkit.switcher(switcher).show(index)
    }

    switcherItems.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault()
        // get data-tab value
        const tab = e.target.getAttribute('data-tab')
        if (tab === 'lessons') {
          // show lessons
          document.getElementById('switch_section').classList.add('lessons')
        } else {
          document.getElementById('switch_section').classList.remove('lessons')
        }

        const lessonAnchor = e.target.getAttribute('href')
        // add anchor to url
        window.history.pushState({}, '', lessonAnchor)
        e.target.classList.add('active')
      })
    })

    jQuery(function ($) {
      // Hide all buyed_tab
      $('.buyed_tab').hide()

      // Create an ajax function call to wp_ajax_get_user_enrollments
      $.ajax({
        type: 'GET',
        dataType: 'json',
        url: ajax_login_object.ajaxurl,
        data: {
          action: 'get_user_enrollments', //calls wp_ajax_get_user_enrollments
        },
        success: function (data) {
          // Show all buyed_tab
          $('.buyed_tab').show()

          const courses = data?.courses || []

          console.log('courses', courses)

          $('.single_lesson_card').each(function (index, course) {
            const id = $(course).data('courseid')
            const activeLabel = $(course).find('.active_label')
            const enrolled_course = courses.find((e) => e.course_id === id)
            if (enrolled_course) {
              activeLabel.addClass('loaded')
              let perc = Math.floor(enrolled_course.percentage_completed * 100)
              if (perc < 100 && perc > 0) {
                activeLabel.addClass('progress')
              } else if (perc >= 100) {
                activeLabel.addClass('completed')
              }
            } else {
              if ($(course).hasClass('buyed_tab')) $(course).parent().remove()
              activeLabel.remove()
            }
          })
        },
        error: function (error) {
          console.error('error', error)
          $('.active_label').hide()
          $('.buyed_tab').hide()
        },
        complete: function () {
          console.log('complete')
        },
      })
    })
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
}
