import '../util/ajax-login-script'
import '../util/ajax-signup-script'
import '../util/download-csv'
import '../util/download-xlsx'
import '../util/modals'
import '../util/sharing-box'
import '../util/ajax-account'
import '../util/ajax-challenge'

import UIkit from 'uikit'

import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import Rellax from 'rellax'

import AOS from 'aos'
import 'aos/dist/aos.css' // You can also use <link> for styles

const DEV = process.env.NODE_ENV === 'development'

export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('init common')

    // Rellax
    new Rellax('.rellax')

    // Aos
    AOS.init({
      once: true,
    })

    // Loader
    const loader = document.getElementById('loader')
    if (!DEV) {
      hideLoader()
    }

    setTimeout(function () {
      hideLoader()
    }, 2000)
    window.addEventListener('load', function () {
      hideLoader()
    })

    // Remove no-js
    document.documentElement.className = 'js'

    // Test touch
    if (!('ontouchstart' in document.documentElement)) {
      document.documentElement.classList.add('no-touch')
    } else {
      document.documentElement.classList.add('touch')
    }

    // Lesson link
    var lessonBtns = document.getElementsByClassName('open_modal_lesson')

    var setRedirectUrl = function () {
      var redirect_url = this.getAttribute('data-link')
      ajax_login_object.redirecturl = redirect_url
    }

    for (var i = 0; i < lessonBtns.length; i++) {
      var link = lessonBtns[i].dataset.link
      lessonBtns[i].addEventListener('click', setRedirectUrl, false)
    }

    // Hamburger
    const menu_btn = document.querySelectorAll('.menu_btn')[0]
    const menu_full = document.querySelectorAll('.menu-full')[0]
    const main_header = document.querySelectorAll('.header')[0]
    const menu_item = document.querySelectorAll('.menu-item')

    menu_item.forEach((item, i) => {
      item.addEventListener('click', function (e) {
        menu_full.classList.remove('show_menu')
        document.body.classList.remove('noScroll')
        menu_btn.classList.remove('active')
        main_header.classList.remove('menu_opened')
      })
    })

    menu_btn.addEventListener('click', function (e) {
      this.classList.toggle('active')
      menu_full.classList.toggle('show_menu')
      document.body.classList.toggle('noScroll')
      main_header.classList.toggle('menu_opened')
    })

    // Gallery click
    var util = UIkit.util

    util.on('figure a', 'click', function (e) {
      e.preventDefault()

      var source = event.target.src

      UIkit.lightboxPanel({
        items: [{ source: source }],
      }).show()
    })

    // var galleries = document.getElementsByClassName('blocks-gallery-grid');
    // for (var j = 0, len = galleries.length; j < len; j++){
    //   var g = galleries[j];
    //   for (var i = 0, len = g.children.length; i < len; i++)
    //   {
    //     (function(index){
    //       g.children[i].onclick = function(event){
    //         console.log(index);
    //         console.log(event.target);
    //         console.log(this);
    //
    //         var source = event.target.src;
    //
    //         UIkit.lightboxPanel({
    //             items: [
    //               {source: source},
    //             ],
    //             index: index
    //         }).show();
    //
    //       }
    //     })(i);
    //   }
    // }

    // Banner
    var close_banner = document.getElementById('close_banner')
    if (close_banner) {
      close_banner.addEventListener('click', function () {
        document.cookie = 'show_banner=false'
      })
    }

    // Tooltips
    tippy('.right_tooltip', {
      content: (reference) => reference.getAttribute('title'),
      placement: 'right',
      theme: 'light',
    })

    tippy('.top_tooltip', {
      content: (reference) => reference.getAttribute('title'),
      placement: 'top',
      theme: 'light',
    })

    function hideLoader() {
      loader.classList.add('hide')
    }

    function showLoader() {
      loader.classList.add('show')
    }

    // Adjust event table content
    const table = document.querySelector('.event-table-wrapper')
    if (table) {
      const countRows = table.querySelectorAll('.single-event-table-row')
      const noMatchDiv = document.querySelector('.no-match-found')
      if (countRows.length === 0) {
        table.classList.add('uk-hidden')
        noMatchDiv.classList.remove('uk-hidden')
      }

      // let textArr = [];
      // dates.forEach(function (d, i) {
      //   if (textArr.indexOf(d.innerText) > -1) {
      //     d.innerText = '';
      //     d.parentNode.classList.remove('tr-divider')
      //   } else {
      //     textArr.push(d.innerText);
      //   }
      // });

      const modalTriggers = table.querySelectorAll('.event-trigger'),
        singleEventModal = document.querySelector('#modal-calendly-single'),
        iframe = singleEventModal.querySelector('iframe')

      modalTriggers.forEach((trigger) => {
        const btn = trigger.querySelector('span')
        const src = trigger.getAttribute('data-event-url')
        console.log(src)
        btn.addEventListener('click', function () {
          UIkit.util.on(singleEventModal, 'beforeshow', function () {
            iframe.setAttribute('src', src)
          })
          UIkit.modal(singleEventModal).show()
          UIkit.util.on(singleEventModal, 'hidden', function () {
            iframe.setAttribute('src', '')
          })
        })
      })
    }

    // Registration form
    const registrationForm = document.querySelector('#registration-slider')
    if (registrationForm) {
      const steps = document.querySelectorAll('.steps')
      steps.forEach((step) => {
        const btn =
          step.querySelector('.registration-form-btn') ||
          step.querySelector('input[type="submit"]')
        const errorBox = step.querySelector('.error-general')
        btn.addEventListener('click', (e) => {
          const inputs = step.querySelectorAll('.required')
          const checkForEmpty = Array.from(inputs).map((el) => {
            if (el.type === 'select-one') {
              return el.value && el.value !== '0'
            }
            return el.value && el.value !== ''
          })
          if (checkForEmpty.filter((el) => el).length === inputs.length) {
            errorBox.classList.add('uk-invisible')
            UIkit.slider('#registration-slider').show('next')
          } else {
            e.preventDefault()
            errorBox.classList.remove('uk-invisible')
          }
        })
      })
    }
    // Scroll to top of registration form on tablet / mobile
    const registrationSlider = document.getElementById('registration-slider')
    //body has class page-template-tpl-registration

    if (window.innerWidth < 1024 && registrationSlider) {
      registrationSlider.addEventListener('itemshow', function () {
        const topPosition =
          registrationSlider.getBoundingClientRect().top + window.scrollY - 100
        window.scrollTo({ top: topPosition, behavior: 'smooth' })
      })
    }

    // Thinkific sso
    const thinkificBtns = document.querySelectorAll('.thinkific_sso')

    thinkificBtns &&
      thinkificBtns.forEach((thinkificBtn) => {
        thinkificBtn.addEventListener('click', function (event) {
          event.preventDefault()
          const getHref = this.getAttribute('href')
          const thinkificUrl = (this.getAttribute('data-return-url') || getHref)
            .replace('https://dataninjait.thinkific.com', '')
            .replace('https://school.dataninja.it', '')

          const errorUrl =
            this.getAttribute('data-error-url') ||
            'https://openthebox.io/pagina-di-errore/'

          // Get jwt token using ajax, calling get_thinkific_jwt
          fetch(`${ajax_login_object.ajaxurl}?action=get_thinkific_jwt`)
            .then((response) => response.json())
            .then((data) => {
              const jwt = data.jwt
              const newUrl = `https://dataninjait.thinkific.com/api/sso/v2/sso/jwt?jwt=${jwt}&return_to=${thinkificUrl}&error_url=${errorUrl}`

              // console.log(jwt, newUrl)

              if (jwt) {
                window.location.href = newUrl
              }
            })
            .catch((error) => {
              console.error('error', error)
              window.location.href = errorUrl
            })
        })
      })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    console.log('finalize common')
  },
}
